.column-filter--wrapper {

    
    .filter-button {
        background: none;
        font-family: var(--font-family-gotham-book);
        font-weight: 500;
        font-size: var(--font-size-14);
        color: var(--color-black-600);
        border-color: var(--color-gray-300);
        border-radius: 7px;
        border: 2px solid var(--color-gray-300);
    }

    .filter-list {
        .filter-group {
            font-family: var(--font-family-gotham-book);
            font-weight: 500;
            font-size: var(--font-size-14);
            color: var(--color-black-600);
            border-bottom: 1px solid var(--color-gray-300);
            margin: 0;
            padding: 8px 16px;
        }

        .filter-item {
            text-transform: uppercase;
            color: var(--color-black-600);
        }
    }
}