.accordion-component {
    width: 100%;

    .accordion-item {
        width: 100%;
        border-top-width: 0;
        border-color: var(--color-gray-300);

        .accordion-button {
            width: 100%;    
            font-size: var(--font-size-22);
            font-family: var(--font-family-gotham-bold);
            color: var(--color-black-600);
            text-transform: uppercase;
            padding: 15px 20px 15px 0;
            display: flex;
            align-items: center;

            &:hover {
                background: none;
            }

            .accordion-button--icon {
                display: flex;
                width: 30px;
                color: var(--color-mojo-500);
                transition: all 0.3s ease-in-out;
            }
        }
    }

    .accordion-panel {
        width: 100%;
        padding: 0;
    }
}