#mojoSidebar {
    position: absolute;
    top: 80px;
    left: 0;
    z-index: 4;
    transition: width 0.3s;
    box-shadow: 10px -1px 12px -4px rgba(0,0,0,0.17);
    height: 100%;

    .sidebar-content {
        position: relative;
        align-items: center;
        height: 100%;
        width: 100%;
    }

    .sidebar-toggle-button {
        position: absolute;
        top: 10px;
        background: none;
        color: var(--color-white-100);

        & svg {
            width: 28px;
            height: auto;
        }
    }

    .sidebar-items {
        margin-top: 70px;
        padding: 0 10px 0 20px;
        width: 100%;

        .sidebar-item {
            width: 100%;
            margin-bottom: 25px;
        }

        .sidebar-item-stack {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            width: 100%;
        }

        .sidebar-item-inner-wrap {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 30px;
            height: 30px;

            & a.chakra-button {

                &:focus {
                    background: none;
                }

                & svg {
                    width: 30px;
                    height: auto;
                }
            }
            
        }

        .sidebar-item-link {

            &.active {
                .sidebar-item-text {    
                    color: var(--color-mojo-200);
                }
            }
        }
    }
}