.client-list {

.datatable-main {
  display: flex;
  flex-direction: column;
  gap: var(--chakra-space-4);
}
.datatable-loading {
  flex: 1;
  display: grid;
  place-content: center;
}

.client-title-navigation-action-link {
  color:#73CEE2;
  cursor: pointer;
}

.client-title-navigation-action-link:hover {
  color:#231f20;
}

.clients-page--header {
  justify-content: space-between;
  padding-bottom: 20px;
  border-bottom: 1px solid var(--color-gray-300);

  .clients-header--left {
    align-items: center;

    .clients-heading {
      font-size: var(--font-size-30);
      font-family: var(--font-family-gotham-bold);
      color: var(--color-black-600);
    }
  }
.budgets-header--right {

.add-client-btn {
    background-color: var(--color-mojo-200);
    border-radius: 7px;
    font-family: var(--font-family-gotham-medium);
    font-size: var(--font-size-14);
    color: var(--color-white-100);
    padding: 10px;
    margin-left: 10px;
  }
}

}
}
