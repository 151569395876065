.budgets-page--wrapper {

    .budgets-page--header {
        justify-content: space-between;
        padding-bottom: 20px;
        border-bottom: 1px solid var(--color-gray-300);

        .budgets-header--left {
            align-items: center;
            
            .budgets-heading {
                font-size: var(--font-size-30);
                font-family: var(--font-family-gotham-bold);
                color: var(--color-black-600);
            }
        }

        .budgets-header--right {

            .location-select-wrapper {

                .budgets-location-select {
                    border-color: var(--color-gray-300);
                    border-radius: 7px;
                    border: 2px solid var(--color-gray-300);
                    font-size: var(--font-size-14);
                    min-width: 250px;
                }
            }

            .option-upload-btn {
                background-color: var(--color-mojo-200);
                border-radius: 7px;
                font-family: var(--font-family-gotham-medium);
                font-size: var(--font-size-14);
                color: var(--color-white-100);
                padding: 10px;
                margin-left: 10px;
            }

            .budgets-upload-btn {
                background-color: var(--color-mojo-200);
                border-radius: 7px;
                font-family: var(--font-family-gotham-medium);
                font-size: var(--font-size-14);
                color: var(--color-white-100);
                padding: 10px;
                margin-left: 10px;
            }

        }
    }

    .budgets-page--body {
        display: flex;
        flex-direction: column;
    }
}