// dashboard-container
// dashboard-header
// dashboard-heading
// dashboard-filters
// dashboard-filter-company
// dashboard-filter-department
// dashboard-filter-source

.dashboard-container {

    & .dashboard-header {
        display: flex;
        justify-content: space-between;
        
        & .dashboard-heading {
            font-size: var(--font-size-30);
            color: var(--color-black-600);
        }
    }

}

.dashboard-filters {
    margin-bottom: 2.4375rem;
    justify-content: flex-end;

    & .dashboard-filter {
        font-size: var(--font-size-14);
        border: 2px solid var(--color-gray-300);
        border-radius: 7px;
        color: var(--color-black-600);
    }


}

.dashboard-grid--layout {
    grid-template-rows: repeat(1, 1fr);
    grid-template-columns: 1fr 3fr;
    gap: 1rem;
}

.widget-column {
    display: grid;
    row-gap: 1.875rem;
}

.chart-column {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
}
