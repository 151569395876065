/*
    .ant-table-wrapper 
    .ant-spin-nested-loading
    .ant-spin-container
    .ant-table 
    .ant-table-container
    .ant-table-content
    table
    thead.ant-table-thead
    th.ant-table-cell
    tbody.ant-table-tbody
    tr.ant-table-row
    td.ant-table-cell
*/
/* 
    Global Mojo Table Styles
*/
.ant-table-wrapper {
    
    thead.ant-table-thead {
    
        th.ant-table-cell {
            font-family: var(--font-family-gotham-book);
            font-size: var(--font-size-13);
            color: var(--color-mojo-600);
            text-transform: uppercase;
            background: var(--color-white-100);
            border: none;
    
            // Use for removing border at end of column headers
            // &:before {
            //     display: none;
            // }
        }
    }
    
    tbody.ant-table-tbody {
    
        tr.ant-table-row {
            border-radius: 10px;
            border-color: var(--color-gray-300);
    
            td.ant-table-cell {
                font-family: var(--font-family-gotham-book);
                font-size: var(--font-size-12);
                color: var(--color-black-700);
                background: var(--color-white-100);
            }
        }
    
    }
    
    // Ascending and descending arrows
    .ant-table-column-sorter-inner {
        .ant-table-column-sorter-down.active {
            color: var(--color-mojo-200);
        }
        .ant-table-column-sorter-up.active {
            color: var(--color-mojo-200);
        }
    }
}