.datepicker-container {
    position: relative;
    font-family: var(--font-family-gotham-book);
    font-size: var(--font-size-14);
    height: 2.5rem;
    width: 16.25rem;

    
    & .react-datepicker-wrapper {
        display: flex;
        align-items: center;
        height: 100%;

        & .react-datepicker__input-container {
            height: 100%;
        }
    }

    & .datepicker {
        position: relative;
        padding: 0 32px 0 12px;
        border-radius: 7px;
        border: 2px solid var(--color-gray-300);

        &::placeholder {
            color: var(--color-black-600);
        }

    }

    & .datepicker-icon {
        position: absolute;
        right: 12px;
        top: 50%;
        transform: translateY(-50%);
        color: var(--color-gray-500);
    }
}
