.client-form {
  display: grid;
  grid-template-rows: 1fr;
  gap: var(--chakra-space-5);
  &-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1rem;
  }
  &-subheader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  &-body {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto repeat(2, minmax(0, 1fr));
    gap: var(--chakra-space-5);
    :first-child {
      grid-column: 1 / -1;
    }
    :nth-child(2){
      grid-column: 1 / -1;
    }
    :nth-child(3){
      grid-column: 1 / -1;
    }
    :nth-child(4){
      grid-column: 1 / -1;
    }
  }
  .client-group{
    width: 100%;
  }
}

.full-width-grid-child {
    grid-column: 1 / -1;
    width: 100%;
}

.full-width-grid-child.extra-margin {
  margin: 16px 0px;
}

.exclude-from-grid {
  grid-column: span 2;
  gap: 8px;
  align-items: self-start;
  margin-top: -86px;
}

.exclude-from-grid > div:nth-child(1) > div:nth-child(1) {
  height: 41px;
}

.exclude-from-grid > div:nth-child(1) > div:nth-child(2) {
  height: 52px;
}

.exclude-from-grid > div:nth-child(2){
  vertical-align: bottom;
  height: 205px;
}
