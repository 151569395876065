.add-edit-presentations-form {
    // &-body {
    //     :first-child {
    //         grid-column: 1 !important;
    //     }
    //     .notes {
    //         grid-column: 1 / span 2;
    //     }
    // }

    // input::-webkit-file-upload-button {
    //     display: none;
    // }
    // input::file-selector-button {
    //     display: none;
    // }

    .presentation-upload {
        flex-grow: 1;
    }

    .file-input {
        display: none;
    }

    .custom-file-input {
        color: transparent;
        width: 100%;
    }
    .custom-file-input::-webkit-file-upload-button {
        visibility: hidden;
    }
    .custom-file-input::before {
        width: 95%;
        content: "Upload file";
        color: black;
        display: inline-block;
        // background: -webkit-linear-gradient(top, #f9f9f9, #e3e3e3);
        // border: 1px solid #999;
        // border-radius: 3px;
        padding: 10px 12px;
        // outline: none;
        // white-space: nowrap;
        // -webkit-user-select: none;
        cursor: pointer;
        // text-shadow: 1px 1px #fff;
        font-weight: 500;
        font-size: 12px;
        font: normal normal normal 12px/40px;
        letter-spacing: 0px;
        background: #ffffff 0% 0% no-repeat padding-box;
        border: 2px solid #d5d5d5;
        border-radius: 7px;
    }
    .custom-file-input:hover::before {
        border-color: black;
    }
    .custom-file-input:active {
        outline: 0;
    }
    .custom-file-input:active::before {
        background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
    }
}
