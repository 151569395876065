.client {
  flex: 1;
  display: grid;
  grid-template-rows: min-content 1fr;
  &-form-header {
    display: flex;
    flex-direction: row;
    gap: var(--chakra-space-10);
    align-items: center;
  }
  &-modal {
    display: grid;
    place-self: start;
    width: 55ch;
  }
}