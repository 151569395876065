
.budget-form {
  &-body {
    :first-child {
      grid-column: -1/ 1 !important; 
    }
    .notes {
      grid-column: 1 / span 2;
    }
  }
}
